<template>
  <vue-editor
    id="editor"
    v-model="editor"
    :editorToolbar="customToolbar"
  ></vue-editor>
</template>

<script>
  import { VueEditor } from "vue2-editor";

  export default {
    name: "RichEditor",

    components: { VueEditor },

    props: ["value"],

    data() {
      return {
        editor: "",
        customToolbar: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          ["link"],
          ["clean"], // remove formatting button
        ],
      };
    },

    watch: {
      value: {
        immediate: true,
        handler(nv) {
          this.editor = nv;
        },
      },

      editor: {
        handler(nv) {
          this.$emit("input", nv);
        },
      },
    },
  };
</script>

<style>
  #editor {
    height: 300px;
    overflow-y: auto;
  }
</style>
